@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat"), url(./fonts/Mont-med.woff2) format("woff2");
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Bold.woff2") format("woff2"),
    url("./fonts/Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-display: swap;
  src: local("Montserrat Thin"),
    url(./fonts/Montserrat-Thin.woff2) format("woff2");
}

@font-face {
  font-family: "Montserrat Thin";
  src: url("./fonts/Montserrat-Thin.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat EB";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("Montserrat Extra Bold"),
    url(./fonts/Montserrat-ExtraBold.woff2) format("woff2");
}

@font-face {
  font-family: "Montserrat EB Italic";
  font-weight: bold;
  font-display: swap;
  src: url(./fonts/Montserrat-ExtraBoldItalic.woff2) format("woff2"),
    url(./fonts/Montserrat-ExtraBoldItalic.woff) format("woff");
}

* {
  transition: color 0.2s, background-color 0.2s, fill 0.2s, stroke 0.2s;
}

html,
body,
#app,
#app > div {
  height: 100%;
  background-color: var(--color-bg);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.footer {
  padding: 0.5rem 1.5rem 0.5rem !important;
  bottom: 0;
  left: 0;
  position: relative;
  width: 100%;
  background: var(--color-footer-bg) !important;
  color: #a4a5aa;
  height: 40px;
  font-size: 10px;
  margin-bottom: 0;
  right: 0;
}

.footer a:hover {
  color: #efefef;
}

.home-container {
  height: 100%;
}

/* HQ -- Adjust all input fields font */
.textarea,
.input,
select {
  font-family: "Montserrat", sans-serif;
}

/* HQ -- Light Theme Fine-tuning */
.has-text-white {
  color: var(--color-lighter-text) !important;
}

@media only screen and (max-width: 426px) {
  #home-container {
    padding: 0 1rem;
  }
  #mqtt_form.table tr td:nth-child(odd) {
    width: 50%;
  }
}
