/* Home */
.home-title {
  margin: 2rem 0 3rem 0;
  font-weight: bold;
  color: var(--color-light-text);
  position: relative;
  top: 14px;
  padding-left: 1rem;
}

.nav-tabs,
.change-view-title {
  position: absolute;
  right: 30px;
  top: -6px;
}

.change-view-title {
  font-size: 11px;
  right: 180px;
  top: 10px;
}

.nav-tabs {
  border-color: transparent !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: initial !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover,
.nav-link.active:hover {
  border-color: transparent !important;
  outline: none !important;
}

.nav-link.active svg path {
  fill: var(--color-secondary);
}

.fail-message {
  text-align: left;
  padding: 1rem 2.5rem;
}

/* Loading Spinner */
.spinner {
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
  Set the color of the icon
*/
.spinner svg path,
.spinner svg rect {
  fill: #2196f3;
}

/* Animations */
@keyframes change {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
    color: #efefef;
  }
  50% {
    opacity: 0;
    color: #393d3f;
  }
  100% {
    opacity: 1;
    color: #292929;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
    color: #efefef;
  }
  50% {
    opacity: 0;
    color: #393d3f;
  }
  100% {
    opacity: 1;
    color: #292929;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
    color: #efefef;
  }
  50% {
    opacity: 0;
    color: #393d3f;
  }
  100% {
    opacity: 1;
    color: #292929;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
    color: #efefef;
  }
  50% {
    opacity: 0;
    color: #393d3f;
  }
  100% {
    opacity: 1;
    color: #292929;
  }
}

/* Account Page */
#account-page {
  overflow: hidden;
}

.columns.account-wrapper {
  position: relative;
}

.account-logout-btn-wrapper {
  position: absolute;
  right: 0;
  top: 36px;
}

.account_divider-circle {
  width: 150px;
  position: absolute;
  left: calc(50% - 75px);
  top: 0;
}

.account_divider-circle svg {
  position: relative;
  left: 1px;
}

.account_user-form {
  padding: 0 15% 0 7%;
}

.account_token {
  padding-left: 20%;
}

.account_token .auth-pw {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.btn-small {
  background-color: var(--color-secondary);
  width: 50px;
  height: 44px;
  color: var(--color-bg);
  border-radius: 6px;
  margin-left: 1rem;
  cursor: pointer;
  position: absolute;
  top: -3px;
  right: -70px;
  border-color: aqua;
}

.btn-small.disabled {
  background-color: #a7a7a7;
  cursor: not-allowed;
}

.account-wrapper .save-changes-btn-container {
  margin-top: 2.5rem;
  margin-bottom: 2rem;
}

.account-wrapper .save-changes {
  width: 234px;
}

#copy-tooltip .tooltip-inner {
  background-color: #3f6179;
}

#copy-tooltip .tooltip-arrow::before {
  border-top-color: #3f6179;
}

.email-err-msg {
  display: flex;
  align-items: center;
  width: 100%;
}

#account-page p.error_msg {
  color: orangered;
  font-size: 12px;
  font-weight: normal;
  text-align: -webkit-auto;
  height: 8px;
  margin-top: 6px;
}

#homepage .modal-card-body {
  flex-direction: column !important;
  align-items: baseline !important;
}

#homepage .flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 700;
}

#homepage .columns {
  padding: 10px 0;
  width: 100%;
  margin-bottom: 0;
}

#homepage p {
  font-size: 11px;
  margin-bottom: 0;
}

.account_divider-circle-mobile,
.account-logout-btn-wrapper-mobile,
.account-p-title {
  display: none;
}

.account-p-title-dt {
  text-align: left;
  /* font-weight: 300; */
}

.home-title {
  font-family: "Montserrat EB Italic", sans-serif;
}

.verify-wrapper .logo {
  width: 300px;
  padding-left: 2rem;
}

.verify-content {
  color: var(--color-light-text);
  padding: 2rem;
}

.verify-loading:after {
  animation: dots 3s linear infinite;
  content: "";
}

.pw-reset-wrapper {
  margin-top: -1rem;
}

#pw-reset-wrapper .field,
#pw-reset-wrapper .field-body {
  width: 300px;
}

.email-input {
  min-width: 250px;
}

.email-change-success {
  padding: 10px !important;
  text-align: left;
  font-size: 14.5px;
}

.email-change-user-email-span {
  display: inline-block;
  background: ghostwhite;
  padding: 0 6px;
  border: 1px solid lightgray;
  margin: 0 4px 0 0;
  color: orange;
}

.account-verify-email-mob {
  display: none !important;
  margin: 1rem 0;
}

.account-verify-email-mob button {
  top: 6px;
}

#verify-email-popup .popover-body {
  padding: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
}

.pricing-label {
  white-space: nowrap;
}

.pricing-sel {
  width: 231.98px !important;
}

.underlined {
  position: relative;
}
.underlined:after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  height: 7px;
  width: 100%;
  border: solid 2px #00d1b2;
  border-color: #00d1b2 transparent transparent transparent;
  border-radius: 50%;
  clip-path: inset(0 0 50% 0);
  transform: rotate(-4deg);
}

@keyframes dots {
  0%,
  20% {
    content: ".";
  }
  40% {
    content: "..";
  }
  60% {
    content: "...";
  }
  90%,
  100% {
    content: "";
  }
}

/* Demo Toggler */
.is-demo-toggler {
  position: absolute;
  top: -0.75rem;
  left: 2.5rem;
  width: 200px;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1360px) {
  .account_token {
    padding-left: 15%;
  }
  .account-logout-btn-wrapper {
    top: 30px;
  }
  .home-title {
    margin: 2rem 0 2.5rem 0;
    top: 8px;
  }
}

@media only screen and (max-width: 1200px) {
  #account-page.container {
    max-width: 94%;
    padding: 0 10px;
  }
  .account_user-form {
    padding: 0 0 0 4%;
  }
  .account_token {
    display: flex;
    flex-direction: column;
  }
  .account_token .auth-pw {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 950px) {
  .fail-message {
    padding-left: 1.75rem;
  }
  #account-page {
    height: auto !important;
    min-height: 100vh;
    overflow: auto !important;
    padding-bottom: 6rem !important;
  }
  #account-page .account-wrapper {
    flex-direction: column;
    height: auto;
    padding-top: 2rem;
    margin-bottom: 3rem;
  }
  .account_divider-circle {
    display: none;
  }
  .account_divider-circle-mobile,
  .account-p-title {
    display: block;
  }
  #account-page .account-wrapper {
    margin-top: 1.5rem;
  }
  .account-title-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .account-p-title {
    color: #efefef;
    font-family: "Montserrat Thin", sans-serif;
    text-align: left;
  }
  .account-p-title-dt {
    display: none;
  }
  .account-p-left {
    margin-top: 4rem !important;
  }
  .account-p-pricing {
    margin-top: 3rem !important;
  }
  .account_token {
    padding-left: 2rem;
    justify-content: start;
  }
  .account_token .auth-pw {
    margin-top: 0;
  }
  .change-view-title {
    display: none;
  }
  .home-title {
    font-size: 1em;
    text-align: left;
    padding-left: 1.75rem;
    color: #efefef;
  }
  #account-page .account-title-wrapper .home-title {
    padding-left: 1rem;
  }
  #account-page .field-label {
    display: flex;
    align-items: baseline;
  }
  #account-page
    .card-controls-box-mobile
    .card-controls-content-mobile
    .columns {
    padding-right: 1.5rem !important;
  }
  #pw-reset-wrapper input.input {
    height: 50px;
  }
  .is-demo-toggler {
    /* display: none; */
    width: 200px;
    left: calc(50% - 10vw);
  }
}

@media only screen and (max-width: 768px) {
  .is-half {
    width: 50%;
  }
  .pw-submit-success {
    position: static !important;
  }
  .email-change-user-email-span {
    background: none;
  }
  .account-p-copy {
    margin-top: 0 !important;
  }
  .pricing-sel {
    width: 100% !important;
  }
}

@media only screen and (max-width: 590px) {
  #account-page .account-wrapper {
    align-items: baseline;
  }
  .account-logout-btn-wrapper {
    display: none;
  }
  .account-logout-btn-wrapper-mobile {
    display: block;
  }
  .account-p-title {
    text-align: left;
  }
  .account-logout-btn {
    padding: 0 20px !important;
  }
  .account_user-form,
  .account_token {
    width: calc(100% - 68px);
  }
  .account-logout-btn-wrapper {
    right: 8px;
  }
  .account_token {
    justify-content: flex-start;
    padding-left: 4%;
  }
  .account-wrapper .save-changes {
    width: 100%;
  }
  .auth-pw {
    width: 100%;
  }
  .account-verify-email {
    display: none;
  }
  .account-verify-email-desktop {
    display: none !important;
  }
  .account-verify-email-mob {
    display: block !important;
  }
}

@media only screen and (max-width: 500px) {
  .is-demo-toggler {
    width: 100px;
    left: calc(50% - 6vw);
  }
}

@media only screen and (max-width: 424px) {
  .is-demo-toggler {
    display: none;
  }
}
