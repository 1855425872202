.device-wrapper {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 100% 100% 100% 100% 100%;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
  padding-left: 2rem;
  padding-right: 1rem;
}

#device-list {
  width: 50%;
  padding-bottom: 40px;
}

#device-list-scrollbar > div:first-child {
  max-height: calc(100vh - 300px) !important;
}

/* Reset native .card class */
#device-list .card {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

#device-list .list-group-item,
#device-list .list-group-item-meter {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3fr 2fr 2fr 2.5fr;
  border-bottom: 1px solid rgb(128 128 128 / 13%);
  background-color: transparent;
  color: var(--color-light-text);
}

#device-list .list-group-item-button:focus {
  box-shadow: none;
}

#device-list .list-group-item-item,
#device-list .list-group-item {
  background-color: #1d2831;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  margin-bottom: 6px;
  padding-left: 25px;
}

#device-list .list-group-item-virt {
  background-color: #25333d;
  grid-template-rows: 35px;
}

#device-list .device-list-title {
  font-size: 1rem;
  text-align: left;
}

#device-list .device-list-number-wrapper {
  display: inline-block;
}

#device-list .device-list-number-wrapper-double-digit,
#device-list .meter-list-number-wrapper-double-digit {
  position: relative;
  left: -4px;
}

#device-list .device-list-number span {
  position: relative;
  top: 2px;
}

#device-list .device-list-number span.double-digit {
  left: 3px;
}

#device-list .device-icon-img {
  position: relative;
  left: -38px;
}

#device-list .virt-device-icon-img {
  position: relative;
  width: 50px;
  left: -32px;
  top: -6px;
}

#device-list .status-circle {
  width: 24px;
  height: 24px;
  border-radius: 45%;
}

#device-list .device-controls img {
  margin: 0 5px;
  cursor: pointer;
}

#device-list .action-btns {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 16px 0 0;
}

#device-list .list-group-item-virt .action-btns {
  justify-content: flex-end;
  position: relative;
  left: -1.5rem;
}

#device-list .btn-home {
  width: 40px;
  height: 40px;
  position: static;
  padding: 3px;
  margin-left: 0;
}

#device-list .remote-control-dropdown-btn.cursor-nallowed * {
  cursor: not-allowed !important;
}

#device-list .remote-control-dropdown-btn button.btn {
  padding: 0 !important;
}

#device-list #remote-control-dropdown-btn-icon {
  transform: scale(0.9);
}

#device-list .btn-home.btn-remote-control {
  width: 108px;
  margin-left: 1rem;
  padding: 0 6px;
}

#device-list .btn-small.remote-control-all-btn {
  width: calc(100% - 56px);
  min-width: 130px;
  margin-bottom: 1px;
  margin-left: -1rem;
}

#device-list .btn-home.sub-btn {
  box-shadow: 1.5px 1.5px #00abab;
}

#device-list
  .btn-home.sub-btn:not(.remote-control-all-btn):not(
    .remote-control-dropdown-btn
  ) {
  width: 38.5px;
  height: 38.5px;
}

#device-list .btn-home.btn-remote-control.sub-btn:active {
  box-shadow: none;
}

#device-list .remote-control-dropdown-btn button,
#device-list .btn-home.btn-remote-control button {
  color: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#device-list .btn-home.btn-remote-control .dropdown-menu a {
  /* font-size: 12px !important; */
  padding-right: 2rem;
}

#device-list .no-children {
  display: none;
}

#device-list .list-group-item-body {
  text-align: left;
}

.acc-dropdown-btn {
  outline: none;
  border: none;
  background: none;
  position: absolute;
  right: 0;
}

#device-list .acc-dropdown-btn img.fwd {
  animation: flipFwd 0.5s ease-in-out reverse;
}

#device-list .acc-dropdown-btn img.rev {
  animation: flipFwd 0.5s ease-in-out forwards;
}

#device-list .acc-dropdown-btn img.fwd-fin {
  transform: scaleY(1);
  animation: none;
}

#device-list .acc-dropdown-btn img.rev-fin {
  transform: scaleY(-1);
  animation: none;
}

#device-remote-response-tooltip .tooltip-inner {
  background-color: #3f6179;
  text-transform: capitalize;
}

#device-remote-response-tooltip .tooltip-arrow::before {
  border-top-color: #3f6179;
}

@keyframes flipFwd {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(-1);
  }
}

ul.meters-list {
  padding-left: 4%;
}

#device-list .list-group-item-meter {
  padding: 0.5rem;
  border-left: 1px solid #878787;
  padding-left: 0;
}

#device-list .list-group-item-err {
  grid-template-columns: 10% auto !important;
}

#device-list .line-border {
  height: 100%;
  border-left: 1px solid #878787;
  border-bottom: 1px solid #878787;
  width: 70px;
  border-bottom-left-radius: 10px;
  position: relative;
  top: -50%;
  left: -1px;
}

#device-list .standalone-sm-title {
  height: 40px;
  background: #36444f !important;
  color: #d7d7d7;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px;
  padding-left: 1rem;
}

#device-list .list-group-card-zee .device-list-title-meter {
  position: relative;
  top: -4px;
}

#device-list .list-group-card-zee .device-list-title-meter-type {
  font-size: 8px;
  font-weight: 400;
  position: absolute;
  left: 0;
  top: 22px;
}

/* MEDIA QUERIES */

@media only screen and (min-width: 1920px) {
  #device-list .line-border {
    width: 46px;
  }
}

#device-list .list-group-item-meter:first-child .line-border {
  height: 30px;
  top: -8px;
  position: relative;
}

#device-list .list-group-item-meter:last-child {
  border-left: none;
}

#device-list .list-group-item-meter:last-child .line-border {
  left: 0;
}

#device-list .list-group-item-meter .action-btns {
  justify-content: flex-start;
  /* margin-left: 1rem; */
  padding: 0 10px;
}

#device-list .device-status {
  margin-left: 14px;
}

.device-step {
  min-width: 100%;
}

.slide-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.slide-exit {
  opacity: 1;
  transform: translateX(0%);
}

.slide-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-enter-active,
.slide-exit-active {
  transition: opacity 100ms, transform 500ms;
}

/* Mobile Dropdown */

.three-dots.dots-button button,
.three-dots.dots-button button:hover,
.three-dots.dots-button button:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.three-dots.dots-button button::after {
  display: none !important;
}

.three-dots .dropdown-menu {
  border-radius: 10px;
}

.three-dots .dropdown-menu a:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
}

.time-days-mobile .time-day-mobile {
  text-align: left;
  min-height: 2rem;
}

.time-days-mobile .time-day-mobile span {
  margin-right: 1rem;
  min-width: 110px;
  display: inline-block;
}

.time-days-mobile .time-day-mobile input {
  transform: scale(1.5);
}

.MuiDialog-root {
  z-index: 1000000000 !important;
}

#mobile-anim-wrapper .MuiInput-root {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 0 5px;
  width: 100%;
  height: 2.5em;
  background-color: #fff;
}

#mobile-anim-wrapper .MuiFormControl-root {
  width: 100%;
}

#mobile-anim-wrapper .MuiInputBase-input {
  font-size: 12px !important;
}

#mobile-anim-wrapper .MuiFormControl-root .MuiInput-root.Mui-focused {
  outline: #ffc107 auto 4px;
}

#mobile-anim-wrapper .MuiInput-root.Mui-focused .MuiButtonBase-root {
  height: 100%;
}

#mobile-anim-wrapper .MuiInputBase-input {
  left: 0;
  width: 100% !important;
}

/* User Manual Link */
#user-manual-pdf-link {
  position: fixed;
  bottom: 20%;
  right: -132px;
  /* z-index: 200; */
  transition: all 0.2s ease-in-out;
  width: 170px;
  height: 32px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 4px 0;
  background: #f3f3f3;
  background: -webkit-linear-gradient(
    to left,
    #c9c9c7,
    #ffffff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #c7c8c9, #ffffff);
  -webkit-box-shadow: -5px 5px 23px -11px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: -5px 5px 23px -11px rgba(0, 0, 0, 0.6);
  box-shadow: -5px 5px 23px -11px rgba(0, 0, 0, 0.6);
  transform: translateX(0);
}

#user-manual-pdf-link:hover {
  transform: translateX(-130px);
}

#user-manual-pdf-link-content {
  color: #486370;
  box-sizing: content-box;
}

#user-manual-pdf-link-content svg {
  margin-right: 10px;
}

#user-manual-pdf-link-content a {
  font-size: 10px;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1919px) {
  #device-list {
    width: 70%;
  }
}

@media only screen and (max-width: 1600px) {
  #device-list .line-border {
    width: 50%;
  }
}

@media only screen and (max-width: 1475px) {
  #device-list .device-list-number-wrapper {
    position: relative;
    left: 16px;
  }
  #device-list .list-group-item-item,
  #device-list .list-group-item {
    padding-left: 0.5rem;
  }
  #device-list .device-list-number-wrapper-double-digit {
    left: 12px;
  }
  ul.meters-list {
    padding-left: 5%;
  }
}

@media only screen and (max-width: 1250px) {
  #device-list .list-group-item-meter {
    grid-template-columns: 1fr 4fr 1.5fr 2.5fr 2fr;
  }
}

@media only screen and (max-width: 1140px) {
  #device-list {
    width: 80%;
  }
}

@media only screen and (max-width: 980px) {
  #device-list {
    width: 96%;
    padding: 0 0 60px 10px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 815px) {
  #device-list .list-group-item {
    grid-template-columns: 1fr 2.2fr 1.75fr 2fr 2.5fr;
  }
  #device-list .list-group-item-meter {
    grid-template-columns: 1fr 4fr 1.5fr 2.5fr 1.2fr;
  }
  #device-list .device-list-number span.double-digit {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    z-index: 0 !important;
    position: static !important;
  }
  ul.meters-list {
    padding-left: 1.6rem;
  }
  .device-controls-mobile img {
    width: 30px;
  }
  .status-text {
    display: none;
  }
  .device-wrapper {
    padding-bottom: 100px !important;
  }
  #device-list-scrollbar > div:first-child {
    max-height: initial !important;
    overflow-x: hidden !important;
  }
  #device-list .list-group-item {
    grid-template-columns: auto 3fr 1fr 4.5fr 1.75fr;
  }
  #device-list .action-btns {
    padding: 0 0 0 1vw;
    justify-content: flex-end;
    gap: 16px;
  }
  #device-list .list-group-item-meter {
    grid-template-columns: 1fr 3.5fr 0.75fr 1fr 1fr;
  }
  #device-list .device-list-title,
  #device-list .device-list-title-meter {
    line-height: 1.1;
    /* left: -1rem; */
    position: relative;
  }
  #device-list .list-group-card-zee .device-list-title-meter-wrapper {
    white-space: nowrap;
  }
  #device-list .device-list-title-standalone {
    position: relative;
    left: 4px !important;
    white-space: nowrap;
  }
  #device-list .list-group-item-virt {
    grid-template-columns: auto 3fr 2fr 5fr 1fr !important;
  }
  #device-list .list-group-item-virt .action-btns {
    left: auto;
  }
  #device-list .btn-home.sub-btn:not(.remote-control-all-btn) {
    width: 38.5px;
    height: 38.5px;
    margin-bottom: 1px;
  }
  #device-list .card:not(.list-group-card-zee) .device-list-title-meter {
    margin-left: -1rem;
  }
  #device-list .btn-home.btn-remote-control {
    margin-left: 0;
  }
}

@media only screen and (max-width: 605px) {
  #homepage .flex-start.meter-status {
    justify-content: center;
  }
  #device-list .card:not(.list-group-card-zee) .device-list-title-meter {
    position: absolute;
  }
}

@media only screen and (max-width: 570px) {
  .nav-tabs {
    right: 0 !important;
  }
  #device-list {
    width: 100%;
    padding: 0 6px 0 10px;
  }
  #device-list .device-list-title,
  #device-list .device-list-title-meter {
    font-size: 0.9rem;
    position: relative;
    left: 2px;
  }
  #device-list .device-list-title-zee {
    left: 0;
  }
  #device-list .list-group-card-zee .device-list-title-meter {
    left: -16px;
  }
  #device-list .card:not(.list-group-card-zee) .device-list-title {
    align-items: center;
    display: inline-flex;
  }
  #device-list .card:not(.list-group-card-zee) .device-list-title-meter {
    position: absolute;
    left: 30px;
    margin-left: 0;
  }
  #device-list .status-circle {
    width: 16px;
    height: 16px;
  }
  #device-list .list-group-item-meter .action-btns {
    margin-left: 0;
  }
}

@media only screen and (max-width: 495px) {
  #device-list {
    padding: 0 5px;
  }
  #device-list .list-group-item {
    grid-template-columns: auto 3fr 1.5fr 5fr 2fr;
  }
  #device-list .action-btns {
    padding: 0;
    position: relative;
    left: 8px;
  }
  #device-list .list-group-item-meter {
    grid-template-columns: 1fr 4fr 1fr 1fr auto;
    padding-right: 0;
  }
  #device-list .device-list-title,
  #device-list .device-list-title-meter {
    font-size: 10.5px;
  }
  #device-list .device-icon-img {
    position: relative;
    left: -27px;
    width: 36px;
    top: -1px;
  }
  #device-list .virt-device-icon-img {
    left: -22px;
    top: -5px;
  }
  #device-list .device-list-number {
    font-size: 10px;
  }
  #device-list .btn-home {
    width: 32px;
    height: 32px;
  }
  #device-list .btn-home.sub-btn:not(.remote-control-all-btn) {
    width: 30.5px;
    height: 30.5px;
    margin-bottom: 1px;
  }
  #device-list .btn-home .remote-control-dropdown-btn-img {
    height: 24px;
  }
  #device-list .list-group-card-zee .device-list-title-meter-type {
    top: 16px;
    font-size: 7px;
  }
  .acc-dropdown-btn {
    right: -4px;
  }
  #device-list .acc-dropdown-btn img {
    width: 22px;
  }
  .device-controls-mobile img {
    width: 26px;
  }
  #device-list .device-list-number span {
    top: 1px;
  }
  #device-list .btn-small.remote-control-all-btn {
    min-width: 105px;
  }
  #device-list .btn-small.remote-control-all-btn button {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 400px) {
  #device-list .list-group-item-meter {
    grid-template-columns: 1.5fr 5fr 1fr 1fr auto;
  }
}

@media only screen and (max-width: 375px) {
  #device-list {
    padding: 0 10px 0 0;
  }
  .device-wrapper {
    padding-bottom: 185px !important;
  }
}
