.add-wrapper {
  position: relative;
  display: grid;
  padding-left: 1.5rem;
  /* padding-bottom: 100px; */
  margin-top: 32px;
  width: 70%;
}

.add-circle-wrapper {
  overflow: hidden;
  text-align: center;
}

.add-circle-content {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 25px);
  border: 1px solid #e1e1e1;
  top: -12.5px;
  background-color: var(--color-bg);
  z-index: 10;
  cursor: pointer;
}

.add-circle-content img {
  width: 15px;
}

.add-circle-wrapper:before,
.add-circle-wrapper:after {
  background-color: #4a4e5c;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.add-circle-wrapper:before {
  right: 0;
  margin-left: -50%;
}

.add-circle-wrapper:after {
  left: 0;
  margin-right: -50%;
}

#add-popover {
  font-family: "Montserrat", sans-serif;
}

#add-popover .popover-body {
  font-size: 12px !important;
  padding: 5px 1rem;
}

.device-add-success {
  position: relative;
  top: -4px;
  text-align: left;
  color: limegreen;
  left: -500px;
  transition: all 0.4s ease-in-out;
}

.device-add-success.in {
  left: 0;
}

.device-add-success-mobile {
  top: 25px;
}

.device-add-success-mobile.in {
  left: 16px;
}

#home-add-device-btn-desktop:hover img {
  animation: rotation 0.6s normal forwards ease-in-out;
}

/* Info Balloons */

.info-balloon {
  position: relative;
  top: -2px;
  left: 8px;
  display: inline-block;
  width: 24px;
  height: 24px;
  transform: scale(0.8);
  transform-origin: bottom center;
  z-index: 1;
}

.popover-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  line-height: 20px;
  -moz-border-radius: 50%; /* or 50% */
  border-radius: 50%; /* or 50% */
  border: 1px solid var(--color-dark-text);
  text-align: center;
  font-size: 15px;
  position: relative;
  text-transform: math-auto;
  font-weight: bold;
  cursor: default;
}

#popover-container {
  position: absolute;
  top: -36px;
  left: -2px;
  display: none;
}

#popover-container .popover-arrow {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #d3d3d3;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 4px;
  transform: rotate(45deg) translateX(-50%);
  top: 28px;
  left: 12px;
}

#popover-container .popover-inner {
  position: absolute;
  display: inline-block;
  padding: 6px 15px;
  background-color: #d3d3d3;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 4px;
  z-index: 1;
  text-align: left;
  color: rgb(68, 68, 68);
  font-size: 12px;
  text-transform: none;
  width: max-content;
}

#popover-container.show {
  display: block;
}

#popover-container.multi-line {
  top: -74px;
}

#popover-container.multi-line .popover-arrow {
  top: 66px;
}

#popover-container.multi-line .popover-inner {
  max-width: 280px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1140px) {
  .add-wrapper {
    width: 80%;
  }
}

@media only screen and (max-width: 980px) {
  .add-wrapper {
    width: 96%;
  }
}

@media only screen and (max-width: 768px) {
  .add-wrapper {
    padding-bottom: 100px;
  }
  .add-wrapper-home.add-wrapper-mobile {
    padding-left: 0;
    width: 100%;
    position: fixed;
    bottom: 0;
  }
  .add-wrapper-home.add-wrapper-mobile .add-circle-content {
    width: 63px;
    height: 63px;
    left: auto;
    right: 24px;
    top: 0;
    background: #202b33;
    border: 2.52px solid #e1e1e1;
    box-shadow: 0px 4.095px 8.19px rgb(0 0 0 / 20%);
    z-index: 0;
  }
  .add-wrapper-home.add-wrapper-mobile .add-circle-content img {
    width: 26px;
  }
}
